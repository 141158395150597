.main {
  @apply flex flex-col lg:flex-row lg:px-16 lg:pt-4 lg:h-[90vh];
}

.leftContent {
  @apply basis-1/2 flex flex-col items-center px-4 py-8 text-center lg:text-left lg:items-start lg:justify-center;
}

.rightContent {
  @apply basis-1/2 flex justify-center items-center order-first lg:order-last lg:p-4;
}

.title {
  @apply w-9/12 mb-6 text-grayscale-100 font-bold lg:mb-8;
}

.subtitle {
  @apply font-normal mb-6 lg:mb-8;
}

.imageContainer {
  @apply relative w-[100%] min-w-[24rem] min-h-[20rem] md:min-w-[24rem] md:min-h-[25rem];
}

.image {
  @apply relative hidden lg:block lg:w-[37.5rem] lg:h-[42.5rem] xxl:w-[40rem] xxl:h-[50rem] !important;
}

.imageMobile {
  @apply flex lg:hidden !important;
}
